import React from 'react';
import styled from 'styled-components';
import { BlockchainSection } from 'views/nft/blockchain-section';
import { NftBadgesSection } from 'views/nft/nft-badges-section';
import { NftCaseStudiesSection } from 'views/nft/nft-case-studies-section';
import { NftMainSection } from 'views/nft/nft-main-section';
import { QualitySection } from 'views/nft/quality-section';
import { WeSpecializeInSection } from 'views/nft/we-specialize-in-section';
import { CONSTANTS } from 'constants/styles/constants';
import { AIAssistantSection } from 'modules/ai-assistant-section';
import { FooterSection } from 'modules/footer-section';
import { PerksWithImageSection } from 'modules/perks-with-image-section';
import { array } from 'prop-types';

import { BlockchainCtaSectionExtended } from './blockchain-cta-section-extended';
import { LATEST_RELATED_ARTICLES_SETTINGS, PERKS } from './constants';

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SWrapper = styled.div`
    background-color: var(--secondary-color);
    margin-bottom: 12.5rem;

    ${CONSTANTS.MEDIA.max1024`
        margin-bottom: 6rem;
    `}

    ${CONSTANTS.MEDIA.max500`
        margin-bottom: 4rem;
    `};
`;

const Props = {
    faqData: array.isRequired,
};

export const NftView = ({ faqData }) => {
    return (
        <SContainer>
            <SWrapper>
                <NftMainSection />
                <WeSpecializeInSection />
            </SWrapper>
            <BlockchainSection />
            <AIAssistantSection />
            <NftBadgesSection />
            <BlockchainCtaSectionExtended />
            <QualitySection />
            <PerksWithImageSection
                title="nft-subpage.view-nft-projects-solution-section.title"
                alt="nft-subpage.view-nft-projects-solution-section.alt"
                perks={PERKS}
            />
            <NftCaseStudiesSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </SContainer>
    );
};

NftView.propTypes = Props;
