import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { BLOCKCHAIN_DATA } from 'views/nft/blockchain-section/constant';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { SectionTilesSection } from 'modules/section-tiles-section';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const BlockchainSection = () => {
    const { formatMessage } = useIntl();

    return (
        <SectionTilesSection
            title="nft-subpage.maximizeYourBusiness"
            paragraph={formatMessage(
                {
                    id: 'nft-subpage.weHaveCovered',
                },
                {
                    link: (chunks) => {
                        return (
                            <SPrefixLangLink
                                to={`${PATHS.CASE_STUDIES}/flaree`}
                            >
                                {chunks[0]}
                            </SPrefixLangLink>
                        );
                    },
                },
            )}
            textWrapperCss={css`
                max-width: 49rem;
            `}
            data={BLOCKCHAIN_DATA}
            isParagraphFormattedPreviously
        />
    );
};
